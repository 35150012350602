<template>
    <v-form @submit.prevent="submitForgotPasswordForm(forgotPasswordForm)">
        <portal to="auth-layout-title">
            Forgot Password
        </portal>

        <div class="space-y-default">
            <v-text>
                If you are setting up your account for the first time please enter the email address used when placing your order.
            </v-text>

            <v-text>
                If you arrived here after ordering for the first time, please allow up to 15 minutes for your account to be created in the backend application before requesting a password reset.
            </v-text>

            <v-form-group>
                <v-form-label for="email" class="text-white">
                    Email
                </v-form-label>

                <v-form-input v-model="forgotPasswordForm.email" type="email" name="email" required :disabled="isLoading" />
            </v-form-group>

            <div class="grid grid-cols-2 gap-default">
                <v-button type="submit" class="w-full justify-center" :disabled="isLoading">
                    Submit
                </v-button>

                <router-link :to="{ name: 'auth.login' }" class="w-full">
                    <v-button color="dark" class="w-full">
                        Back to Login
                    </v-button>
                </router-link>
            </div>
        </div>
    </v-form>
</template>

<script>
export default {
    data() {
        return {
            forgotPasswordForm: {
                email: null,
            },
        };
    },
    methods: {
        submitForgotPasswordForm(payload) {
            this.toggleLoading();

            this.$oauth.forgotPassword(payload)
                .then((response) => {
                    this.$router.push({ name: "auth.login" });
                    this.$eventBus.$emit("response", response);
                })
                .catch((error) => {
                    this.$eventBus.$emit("error", error);
                })
                .finally(() => {
                    this.toggleLoading();
                });
        },
    },
};
</script>
